/*  公共接口   */

import axios1 from '@/utils/request'
import register from "@/utils/axios.register";
import axios2 from "@/utils/request_2";


// 获取指定用户信息接口
export const getUser = (params) => {
    return axios1({
        method: 'get',
        url: '/user',
        params
    })
}
//获取省市区
export const getGovernment = (params) => {
    return axios1({
        method: 'get',
        url: '/site/government',
        params
    })
}
// 获取报名学校
export function getSchool(){
    return register.get("/api/recruit/common/school");
}

// 获取未补录退费详情
export function getDetails(id){
  return register.get("/api/refund/offline-information/details?id="+id)
}
// 获取学生状态
export function getStudentStatus(){
  return register.get("api/recruit-v2/offline/student_status_options")
}
// 获取学生来源
export function getStudentSource(){
  return register.get("api/refund/source-options");
}

/**
 * 获取学校 年级 班级 学科等信息
 * @param level 学科等级 (一级学科 二级学科 三级学科 ...)
 * @return {Promise<AxiosResponse<any>>}
 */
export function getSchoolInfo(level=2){
    return axios2.get("/api/common/school",{logic:1,params:{level}});
}

/**
 *  获取学科
 * @param {0|1|2|3} level 科目层级，0：获取所有科目列表，1：只获取顶级，2：只获取子集，3：获取顶级与子集
 * @param {?String} type 科目类型，1：学科，2：德育，3：心理；支持多个type，使用英文逗号分割
 * @param {?String} school_id 校区id；支持多个school_id，使用英文逗号分割
 * @param {?String} id 科目id，支持多个id，使用英文逗号分割
 * @return {Promise<AxiosResponse<any>>}
 */
export function  getSubject(level = 3, type=undefined,school_id=undefined,id=undefined){
    return axios2.get("/api/common/subject",{logic:1,params:{level}});
}

/**
 * 获取列表历史记录
 * @param {String} tbl_name 表名(问后端)
 * @param {String|Number} tbl_id 要查询的数据id
 * @param {Number} page 页码
 * @param {Number} page_size 单页数据数
 * @return {Promise<AxiosResponse<any>>}
 */
export function getHistory(tbl_name,tbl_id,page=1,page_size=20){
    return axios2.get("api/common/public/get-opt-history",{
        params:{
            tbl_name,
            tbl_id,
            page,
            page_size
        },
        logic:1
    });
}

